import React from "react"
import {injectIntl} from "gatsby-plugin-intl"
import Button from "../common/button";
//import {navigate} from "@reach/router";
//import {checkLanguage, paths} from "../../utils";

const TrialButton = ({children,location,intl,type}) => {
    return (
        <Button id="gta_click_pay" className="trial"
                onClick={()=>{window.dataLayer.push({'event': 'trialinfoClick'})}}
                rounded
                btnType={type}
                fullwidth={true}
        >
            {children}
        </Button>
    )
}

export default injectIntl(TrialButton)
