import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import styled from "styled-components"


import ABC from "../../images/svgs/ABC-DEF.svg"
import Mundo from "../../images/svgs/ElMundo-DEF.svg"
import Pais from "../../images/svgs/ElPais-DEF.svg"
import Periodico from "../../images/svgs/ElPeriodico-DEF.svg"
import Espanol from "../../images/svgs/ElEspañol-DEF.svg"
import CronicaGlobal from "../../images/svgs/CronicaGlobal-DEF.svg"
import Vanguardia from "../../images/svgs/LaVanguardia-DEF.svg"
import ExpansionDiario from "../../images/svgs/Expansion-DEF.svg"

const allLogos = [
    <ABC/>,
    <CronicaGlobal/>,
    <Espanol/>,
    <Mundo/>,
    <Pais/>,
    <Periodico/>,
    <ExpansionDiario/>,
    <Vanguardia/>,
]

const PressSection = ({intl}) => {


    const pressLinks = [
        "https://www.abc.es/familia/padres-hijos/abci-reloj-inteligente-para-localizar-mayores-alzheimer-ninos-y-adolescentes-202112180051_noticia.html",
        "https://cronicaglobal.elespanol.com/business/durcal-asistentes-virtuales-dependencia-mexico-chile-npfb_592510_102.html",
        "https://www.elespanol.com/invertia/disruptores-innovadores/disruptores/20210816/geolocalizacion-montanas-cuidar-ninos-mayores/603190094_0.html",
        "https://www.elmundo.es/yodona/lifestyle/2022/05/18/626a6d00fc6c8398598b45b9.html",
        "https://elpais.com/tecnologia/2021-05-31/una-aplicacion-con-nombre-de-estrella-de-las-rancheras-para-cuidar-a-distancia-a-los-ancianos.html#?rel=mas",
        "https://www.elperiodico.com/es/tecnologia/20220110/reloj-inteligente-gps-durcal-13078003",
        "https://www.expansion.com/expansion-empleo/emprendedores/2022/05/18/6284a4dde5fdea2c7f8b4663.html",
        "https://www.lavanguardia.com/economia/emprendedores/20210211/6228651/mayores-coronavirus-cuidar-aplicacion-durcal.html"
    ]


    return (
        <PressWrapperWrapper>
            <PressWrapper>
                {allLogos.map((current,index) => (
                    <a key={index} href={pressLinks[index]} target="_blank">
                        {current}
                    </a>
                ))}
            </PressWrapper>
        </PressWrapperWrapper>
    )
}


const PressWrapperWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

`

const PressWrapper = styled.div`
  padding: 32px 16px 32px 16px;
  display: flex;
  flex-direction: row;
  overflow: auto;
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  
  gap: 0px;
  
  svg {
    height: 16px;
  }
`


export default injectIntl(PressSection);
