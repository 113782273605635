import React from "react"
import {injectIntl} from "gatsby-plugin-intl"
import Button from "../common/button";
import styled from "styled-components"

const CatalogButton = ({location,intl,from}) => {
    return (
        <Button className="catalog"
                onClick={()=>{window.dataLayer.push({'event': 'cataloginfoClick','eventType':from})}}
                rounded
                btnType="primary"
                fullwidth={true}
        >
            <ButtonText>¿Quieres más información del Reloj?</ButtonText>
        </Button>
    )
}
const ButtonText = styled.div`
    margin: 0;
    text-align: center;
    ${({theme}) => theme.font_size.regular};
    font-family: ${({theme}) => theme.font.bold};
    text-decoration: underline;
`

export default injectIntl(CatalogButton)
