import React from "react"
import {injectIntl} from "gatsby-plugin-intl"
import {graphql, useStaticQuery} from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import ProsegurLogo from "../commonV3/ProsegurLogo";
import {StringParam, withQueryParams} from "use-query-params";
import MoreInfoButton from "../commonV3/MoreInfoButton";
import TrialButton from "../commonV3/TrialButton";

const Watch = ({location, intl, query}) => {
    const {purchase_method, userId, promotion_code} = query


    const images = useStaticQuery(graphql`
    query {
      img1: file(
        sourceInstanceName: { eq: "product" }
        name: { eq: "Background-Banner-Compra-M-reloj-durcal" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            originalName
            src
            srcSet
            srcSetWebp
            aspectRatio
            sizes
          }
        }
      },
      img2: file(
        sourceInstanceName: { eq: "product" }
        name: { eq: "Background-Banner-Compra-D-Reloj-Durcal-Teleasistencia" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            originalName
            src
            srcSet
            srcSetWebp
            aspectRatio
            sizes
          }
        }
      }
    }
  `)

    return (
        <WatchSectionWrapper>
            <ImageWrapper>
                <StyledImage fluid={images.img1.childImageSharp.fluid}/>
            </ImageWrapper>
            <ImageWrapper2>
                <StyledImage fluid={images.img2.childImageSharp.fluid}/>
            </ImageWrapper2>
            <div className="DescriptionWrapper">
                <div className="titleDiv">
                    <ProsegurLogo/>
                    <h5>{intl.formatMessage({ id: "homeV3WatchTitle" })}</h5>
                </div>
                {
                    (!new URLSearchParams(location.search)?.get("userId")) &&
                    (<div className="buttonsDiv">
                        <TrialButton
                            location={location}
                            intl={intl}
                            type="whiteopacity"
                        >
                            <ButtonText>{intl.formatMessage({id: "tryFree"})}</ButtonText>
                        </TrialButton>
                        <MoreInfoButton
                            location={location}
                            intl={intl}
                            type="primary"
                            eventType="buy"
                        >
                            <ButtonText>{intl.formatMessage({ id: "buyButton" })}</ButtonText>
                        </MoreInfoButton>
                    </div>)
                }
            </div>
        </WatchSectionWrapper>
    )
}


const WatchSectionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    border-radius: 20px;
    height: 700px;
    flex: none;
    order: 6;
    flex-grow: 0;   
    margin: 16px;
    gap: 56px;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    overflow: hidden;
    @media (min-width: ${props => props.theme.screen.xl}) {
      height: 600px;
      margin: 64px;
    }
  .buttonsDiv {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 16px;
    padding: 16px;
    width: 100%;
    @media (min-width: ${props => props.theme.screen.xl}) {
      width: 75%;
    }
  }

  .titleDiv {
    display: flex;
    flex-direction: column;
    gap:40px;
    align-items: center;
    white-space: pre;
    @media (min-width: ${props => props.theme.screen.xl}) {
      align-items: unset;
    }
  }
  
  .DescriptionWrapper {
    color: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 380px;
    width: 100%;
    h5 {
      margin: 0;
      text-align: center;
      ${({theme}) => theme.font_size.medium};
      font-family: ${({theme}) => theme.font.bold};
    }
    
    @media (min-width: ${props => props.theme.screen.xl}) {
      gap: 32px;
      align-items: unset;
      margin-left: 40px;
      width: 50%;
      h5 {
        gap:20px;
        margin: 0;
        text-align: left;
        ${({theme}) => theme.font_size.xxlarge};
        font-family: ${({theme}) => theme.font.bold};
      }
    }
  }

`

const Price = styled.div`
  align-items: center;
  text-align: center;
  width: 100%;
  ${({ theme }) => theme.font_size.xxlarge};
  font-family: ${({ theme }) => theme.font.extraBold};
  @media (min-width: ${props => props.theme.screen.xl}) {
    align-items: baseline;
    text-align: left;
  }
`
const PriceAnnual = styled.span`
  width: 100%;
  ${({ theme }) => theme.font_size.regular};
  font-family: ${({ theme }) => theme.font.medium};
`

const ButtonText = styled.div`
  margin: 0;
  text-align: center;
  ${({theme}) => theme.font_size.regular};
  font-family: ${({theme}) => theme.font.bold};
`

const ImageWrapper = styled.div`
  margin-top: 0;
  width: 100%;
  background-color: transparent;
  position: absolute;
  z-index: -100;
  
  @media (min-width: ${props => props.theme.screen.xl}) {
    display: none;
  }
`
const ImageWrapper2 = styled.div`
  margin-top: 0;
  width: 100%;
  background-color: transparent;
  position: absolute;
  z-index: -100;
  display: none;
  @media (min-width: ${props => props.theme.screen.xl}) {
    display: block;
  }
`

const StyledImage = styled(Img)`
  display: block;
  flex-grow: 1;
  border-radius: 20px;
`

export default withQueryParams(
    {
        purchase_method: StringParam,
        userId: StringParam,
        promotion_code: StringParam,
    },
    injectIntl(Watch),
)
