import React from "react"
import {injectIntl} from "gatsby-plugin-intl"
import styled from "styled-components"
import AllFeatures from "../commonV3/features/AllFeatures";
import Img from "gatsby-image";
import CatalogButton from "../commonV3/CatalogButton"

const WatchFeatures = ({intl, layoutTypeCompra=false}) => {

    return (
        <>
        <WatchFeaturesSectionWrapper {...{ layoutTypeCompra}}>
            <TittleWrapper>
                <h5>  {intl.formatMessage({id: "homeV3FeaturesSubtitle"})}</h5>
            </TittleWrapper>
            <FeatureIconsWrapper {...{ layoutTypeCompra}}>
                {AllFeatures({intl,layoutTypeCompra})}
            </FeatureIconsWrapper>
            <CatalogButtonWrapper>
                <CatalogButton from={"home"}/>
            </CatalogButtonWrapper>
        </WatchFeaturesSectionWrapper>
        </>
)
}


const WatchFeaturesSectionWrapper = styled.div`
    display: flex;
    padding: 24px 16px;
    @media (min-width: ${props => props.theme.screen.xl}) {
        padding: 24px 64px;
    }
    flex-direction: column;
`

const FeatureIconsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: center;
    align-items: center;
    margin-bottom: 36px;
    width: 100%;
    gap: 13px;

    @media (max-width: ${props => props.theme.screen.xl}) and (max-width: calc(${props => props.theme.screen.xl} - 1px)) {

    }
`

const StyledImage = styled(Img)`
  width: 100%;
  display: block;
  margin-bottom: 16px;
`

const TittleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  
  text-align: center;
  @media (min-width: ${props => props.theme.screen.xl}) {
    display: none;
  }
  
  span {
    ${({theme}) => theme.font_size.small};
    font-family: ${({theme}) => theme.font.thin};
  }
  
  h5 {
    ${({theme}) => theme.font_size.xlarge};
    font-family: ${({theme}) => theme.font.bold};
    padding-bottom: 32px;
    margin: 0;
  }
`
const CatalogButtonWrapper = styled.div`
    @media (min-width: ${props => props.theme.screen.xl}) {
        display: none;
    }
    
`

export default injectIntl(WatchFeatures)
