import ProsegurSVG from "../../images/svgs/VectorProsegur.svg"
import styled from "styled-components";
import {injectIntl} from "gatsby-plugin-intl";
import React from "react";


const ProsegurLogo = () => {
    return (
        <ProsegurLogoWrapper className ="prosegurlogowrapper">
            <ProsegurSVG/>
        </ProsegurLogoWrapper>
    )
}


const ProsegurLogoWrapper = styled.div`
  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 10px;

  width: 139px;
  height: 45px;

  /* Yellow/100 */
  background: #FFD102;
  border-radius: 20px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
`


export default injectIntl(ProsegurLogo)
